<template>
  <div style="font-size: 0.14rem;color: #fff;">
    <div style="padding:0 0.36rem;margin-top:0.3rem">
      <el-row :gutter="20">
        <el-col :span="12">
          <p v-for="(item, index) in statistics" :key=" index " style="margin-bottom: 0.16rem">
            <span>{{ item.label }}</span>
            <span style="color: #00F9FF;font-weight: bold;margin-right: 0.2rem">{{ item.count }}</span>
          </p>
        </el-col>
        <el-col :span="12">
          <p style="text-align: center;color: #00A2FF">异常状态分布</p>
          <div style="width: 100%;height: 1.2rem;margin-top: 0.2rem">
            <pie :data="distribution" title=""></pie>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    pie: () => import('./studentAttendancePie')
  },
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      statistics: [
        { label: '今日课堂考勤次数：', count: 0, code: 'checkTimes' },
        { label: '今日参与课堂考勤人次：', count: 0, code: 'checkStuNum' },
        { label: '今日考勤异常人次：', count: 0, code: 'todayAbnormalNum' },
        { label: '近七日课堂考勤异常最多：', count: '-', code: 'latest7DaysMaxTotalErrorDistrict' }
      ],

      distribution: [
      ]
    }
  },

  watch: {
    data (newData) {
      this.init(newData)
    }
  },

  mounted () {
    this.init(this.data)
  },

  methods: {
    init (data) {
      // data = JSON.parse('{"msg":null,"data":{"mostErrorSchoolName":null,"checkTimes":0,"studentCheckSummaryId":null,"checkStuNum":0,"eduName":"河北省石家庄市长安区教育局","todayAbnormalNum":0,"eduId":"39fa5519dd23fc3be272caf9c6cc8801","mostErrorSchoolId":null,"todayCheckData":[{"stuNum":0,"checkStatus":"旷课"},{"stuNum":0,"checkStatus":"早退"},{"stuNum":0,"checkStatus":"请假"},{"stuNum":0,"checkStatus":"迟到"},{"stuNum":0,"checkStatus":"正常"}]},"status":200}')
      // data = data.data
      this.statistics.forEach(item => {
        if (typeof data[item.code] !== 'undefined') {
          item.count = data[item.code]
        }
      })
      console.log(data)
      if (typeof data.todayCheckData === 'undefined') data.todayCheckData = []
      const distribution = []
      data.todayCheckData.forEach(item => {
        if (item.stuNum) {
          distribution.push({
            name: item.checkStatus,
            value: item.stuNum || 0
          })
        }
      })
      this.distribution = distribution
    }
  }
}
</script>
